export default {
  methods: {
    validateTaxNumber(taxNumber) {
      const regexp = /^([0-9]{8})-([1-5]{1})-([0][1-9]|[1-3][0-9]|[4][0-4]|[5][1])$/;
      const romanianRegexp = /[A-Z]{2}[0-9A-Z]{2,13}/;
      return regexp.test(taxNumber) || romanianRegexp.test(taxNumber);
    },
    validatePhoneNumber(phoneNumber) {
      const regexp = /^[+]?[0-9()-]+$/;
      return regexp.test(phoneNumber);
    },
    validateName(name) {
      const regexp = /^[\p{L} -]+$/u;
      return regexp.test(name);
    },
    validateStringInput(name) {
      const regexp = /^[^-\s][\p{L}\s]+$/u;
      return regexp.test(name);
    },
    validateZipCode(zipCode) {
      const regexp = /^[A-Z0-9][A-Z0-9]{1,8}[A-Z0-9]$/;
      return regexp.test(zipCode);
    },
  },
};
